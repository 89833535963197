import React from 'react';
import SEO from '../../components/seo';
import ReactMarkdown from 'react-markdown';

export default ({ location }) => {
	// const source = data.strapiAbout // legacy, data came from Strapi setup.
	const content = "SKARP set out with a goal to fundamentally improve shaving. In order to do that, we have to elevate the entire shaving experience. Would the technology in The SKARP Razor provide an experience that completely transcends traditional methods? We believe so.\n\nShaving has meant scraping skin with a sharp edge for 30,000 years. For 30 years SKARP’s core team has pioneered scientific and commercial frontiers in light sources, lasers, and laser medicine & surgery aka “light tissue interaction”. We are personally dealing with the same problems we all face in shaving. SKARP is leveraging the sum of this with the aim to bring something better to every household. Throughout the world.\n\nSome of the benefits include:\n\n-No Scratches\n-No Razor Burn\n-No Infection\n-No Itch\n-No Accidental Cutting\n-No Irritation\n-Effortless Smooth Stroke\n-Incredibly Close Shave\n-No Expensive Cartridge Replacement\n-Little To No Water Usage\n-No Painful Waxing or depilatory creams\n-Recyclable Material\n-The SKARP Razor is for Everyone\n-The SKARP Razor is for all, regardless of gender, race or age.\n\nResearch tells us many women become so frustrated with legacy razors they turn to other methods such as depilatory creams and waxing. Both of those come with their own negative side effects.\n\nThe SKARP Razor can be used anywhere, no matter how sensitive the skin. Making shaving those hard to reach and delicate areas so much easier, and discomfort and irritation a thing of the past.";
	const imageSource = "/images/the-skarp-razor-is-for-everyone.jpg";

	return (
		<>
			<SEO
				title={"About SKARP Technologies"}
				canonical={location.href}
			/>
			<img src={imageSource} alt="Woman shaving with SKARP laser razor" className="mb-2" />
			<ReactMarkdown source={content} />
		</>
	)
}